import React from 'react';
import { useMenuContext } from '../../state/Menu';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion';
const NavLinks = () => {
  const { closeMenu } = useMenuContext();
  const pageLinks = [
    {
      id: 'home',
      url: '/',
      variants: {
        visible: {
          x: 0,
          opacity: 1,
          transition: { ease: 'easeOut', duration: 0.4 },
        },
        hidden: { x: -125, opacity: 0 },
      },
    },
    {
      id: 'commercial',
      url: '/commercial',
      variants: {
        visible: {
          x: 0,
          opacity: 1,
          transition: { ease: 'easeOut', duration: 0.42 },
        },
        hidden: { x: -125, opacity: 0 },
      },
    },
    {
      id: 'residential',
      url: '/residential',
      variants: {
        visible: {
          x: 0,
          opacity: 1,
          transition: { ease: 'easeOut', duration: 0.46 },
        },
        hidden: { x: -125, opacity: 0 },
      },
    },
    {
      id: 'fabrication',
      url: '/fabrication',
      variants: {
        visible: {
          x: 0,
          opacity: 1,
          transition: { ease: 'easeOut', duration: 0.52 },
        },
        hidden: { x: -125, opacity: 0 },
      },
    },
    {
      id: 'about',
      url: '/about',
      variants: {
        visible: {
          x: 0,
          opacity: 1,
          transition: { ease: 'easeOut', duration: 0.6 },
        },
        hidden: { x: -125, opacity: 0 },
      },
    },
    {
      id: 'contact',
      url: '/contact',
      variants: {
        visible: {
          x: 0,
          opacity: 1,
          transition: { ease: 'easeOut', duration: 0.7 },
        },
        hidden: { x: -125, opacity: 0 },
      },
    },
  ];

  const list = {
    visible: {
      transition: {
        ease: 'easeIn',
        duration: 0.25,
      },
    },
    hidden: {
      transition: {
        ease: 'easein',
        duration: 0.25,
      },
    },
  };
  return (
    <AnimatePresence>
      <NavLinksWrapper
        className="nav-links"
        initial="hidden"
        animate="visible"
        variants={list}
      >
        {pageLinks.map((pageLink) => (
          <motion.li
            key={pageLink.id}
            variants={pageLink.variants}
            exit={{ x: -125, opacity: 0 }}
          >
            <NavLink
              to={pageLink.url}
              onClick={closeMenu}
              className={`${pageLink.url}-link`}
            >
              {pageLink.id.replace(/^\w/, (c) => c.toUpperCase())}
            </NavLink>
          </motion.li>
        ))}
      </NavLinksWrapper>
    </AnimatePresence>
  );
};

export default NavLinks;

const NavLinksWrapper = styled(motion.ul)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  list-style: none;
  padding-inline-start: 0;
  max-width: 66rem;
  padding-top: 5rem;
  @media screen and (orientation: landscape) and (max-width: 900px) {
    padding-top: 2.5rem;
  }
  z-index: 100;
  li {
    padding: 1.5rem;
    @media screen and (orientation: landscape) and (max-width: 900px) {
      padding: 0.25rem;
    }
  }
  button {
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
  }
`;

const NavLink = styled(Link)`
  color: var(--white);
  font-size: 2.5rem;
  font-weight: normal;
  line-height: 3.2rem;
  text-decoration: none;
  font-weight: 400;
  margin: 0 0.5rem;
  transition: color 0.5s;
  @media screen and (orientation: landscape) and (max-width: 900px) {
    font-size: 1.5rem;
  }

  &.current-page {
    border-bottom: 2px solid var(--white);
    margin-top: 6.5px;
    margin-bottom: 0.25rem;
  }
  &:hover,
  &:focus {
    transition: color 0.5s;
    color: var(--darkgrey);
    outline: none;
  }
`;
