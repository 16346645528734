import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { ImPhone } from 'react-icons/im';
import { IoIosMail } from 'react-icons/io';
import { motion } from 'framer-motion';

const StyledFooter = styled.footer`
  flex-shrink: 0;
  margin-top: auto;
  display: flex;
  justify-content: center;
  background: var(--lightgrey);
  padding: 4rem 0;
  @media (min-width: 1024px) {
    height: 184px;
  }
  .footer-container {
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    flex-direction: column;
    @media (min-width: 1024px) {
      flex-direction: row;
      width: 95%;
      max-width: 1540px;
    }
  }
  .branding {
    img {
      width: 235px;
      height: auto;
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-inline-start: 0;
    margin: 0;
    @media (min-width: 1024px) {
      flex-direction: row;
      width: 100%;
      height: 100%;
    }
    li {
      text-decoration: none;
      list-style: none;
      &:not(.branding) {
        margin: 1rem 0;
      }
    }
  }
  a {
    color: var(--black);
    text-decoration: none;
    display: flex;
    gap: 5px;
    align-items: center;
  }
`;

const itemVariants = {
  offscreen: {
    opacity: 0,
  },
  onscreen: {
    opacity: 1,
    transition: {
      ease: [0.19, 1, 0.22, 1],
      duration: 1.5,
    },
  },
};

export default function Footer() {
  return (
    <StyledFooter className="footer">
      <div className="footer-container">
        {/* <p>&copy; Sheet Metal Systems Inc. {new Date().getFullYear()}</p> */}
        <motion.ul
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.15 }}
        >
          <motion.li variants={itemVariants}>
            <StaticImage
              className="branding"
              src="../images/branding.png"
              alt="SMS Logo"
            />
          </motion.li>
          <motion.li variants={itemVariants}>Hollister, CA</motion.li>
          <motion.li variants={itemVariants}>License #972833</motion.li>
          <motion.li variants={itemVariants}>
            <a href="tel:18316359090" title="Call Sheet Metal Systems Office">
              <ImPhone /> <span>(831) 635-9090</span>
            </a>
          </motion.li>
          <motion.li variants={itemVariants}>
            <a
              href="mailto:info@sms13.com"
              title="Send Sheet Metal Systems an Email"
            >
              <IoIosMail />
              {` `}
              <span>info@sms13.com</span>
            </a>
          </motion.li>
        </motion.ul>
      </div>
    </StyledFooter>
  );
}
