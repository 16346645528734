import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useMenuContext } from '../../state/Menu';
import NavLinks from './NavLinks';
import Portal from '@reach/portal';
import { motion, AnimatePresence } from 'framer-motion';

const MobileNavbar = () => {
  const { isMenuOpen, toggleMenu } = useMenuContext();
  const ref = useRef(null);
  const handleTabKey = (e) => {
    if (ref && ref.current) {
      const inputModalElements = ref.current.querySelectorAll(
        'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
      );
      const primaryMenuButton = document.querySelector('.hamburger-react');
      const firstElement = inputModalElements[0];
      const lastElement = inputModalElements[inputModalElements.length - 1];

      switch (document.activeElement) {
        case lastElement: {
          if (!e.shiftKey) {
            primaryMenuButton.focus();
            return e.preventDefault();
          }
          break;
        }
        case firstElement: {
          if (e.shiftKey) {
            primaryMenuButton.focus();
            return e.preventDefault();
          }
          break;
        }
        case primaryMenuButton: {
          if (e.shiftKey) {
            lastElement.focus();
          } else {
            firstElement.focus();
          }
          return e.preventDefault();
        }
        default:
          break;
      }
    }
  };

  const keyListenersMap = new Map([
    [27, toggleMenu],
    [9, handleTabKey],
  ]);

  useEffect(() => {
    function keyListener(e) {
      const listener = keyListenersMap.get(e.keyCode);
      return listener && listener(e);
    }

    document.addEventListener('keydown', keyListener);

    return () => document.removeEventListener('keydown', keyListener);
  }, [keyListenersMap]);
  const variants = {
    visible: {
      x: 0,
      transition: {
        ease: 'easeOut',
        duration: 0.4,
      },
    },
    hidden: {
      x: -200,
      transition: {
        ease: 'easeIn',
        duration: 0.25,
      },
    },
  };
  return (
    <>
      <Portal>
        <AnimatePresence>
          {isMenuOpen && (
            <MobileNav
              ref={ref}
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={variants}
            >
              <NavLinks />
            </MobileNav>
          )}
        </AnimatePresence>
      </Portal>
    </>
  );
};

export default MobileNavbar;

const MobileNav = styled(motion.nav)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 215px;
  background: var(--black);
  display: flex;
  justify-content: flex-start;
  align-items: start;
  z-index: 3;
`;

// const variants = {
//   visible: {
//     x: 0,
//     transition: {
//       ease: 'easeOut',
//       duration: 0.5,
//     },
//   },
//   hidden: {
//     x: -1000,
//     transition: {
//       ease: 'easeIn',
//       duration: 2,
//     },
//   },
// };
// return (
//   <>
//     {isMenuOpen && (
//       <Portal>
//         <MobileNav
//           initial="hidden"
//           animate="visible"
//           ref={ref}
//           variants={{ variants }}
//         >
//           <NavLinks />
//         </MobileNav>
//       </Portal>
//     )}
//   </>
// );
