import React from 'react';
import styled from 'styled-components';
import { Spiral as Hamburger } from 'hamburger-react';
import { useMenuContext } from '../../state/Menu';
import LogoLink from './LogoLink.js';
import { useScroll } from '../../hooks/useScroll';

const DesktopNavbar = () => {
  const { isMenuOpen, toggleMenu } = useMenuContext();
  const { isScrolled } = useScroll();
  return (
    <DesktopNav isMenuOpen={isMenuOpen}>
      <IconWrapper isMenuOpen={isMenuOpen}>
        <Hamburger
          toggled={isMenuOpen}
          toggle={toggleMenu}
          label="Show Navigation Menu"
          hideOutline={false}
        />
      </IconWrapper>
      <LogoLink
        className={isMenuOpen ? 'logo-link hidden' : 'logo-link'}
        isScrolled={isScrolled}
        isMenuOpen={isMenuOpen}
      />
    </DesktopNav>
  );
};

export default DesktopNavbar;

const DesktopNav = styled.nav`
  color: var(--white);
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  box-shadow: none;
  @media (pointer: coarse) {
    * {
      outline: none;
    }
  }
  button {
    border: none;
    background: none;
  }
  .logo-link {
    display: inline-block;
    position: absolute;
    top: 0;
    margin-top: 15px;
    right: 28.5px;
    display: inline-block;
    transition: transform 500ms ease-out;
    &.hidden {
      transform: translatex(500px);
      transition: transform 250ms ease-in;
    }
    @media screen and (min-width: 640px) {
      right: 50px;
    }
  }
  @media screen and (min-width: 1281px) {
    position: ${(props) => (props.isMenuOpen ? 'static' : 'relative')};
  }
`;

const IconWrapper = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  color: var(--lightgrey);
  cursor: pointer;
  will-change: transform;
  transition: ${(props) =>
    props.isMenuOpen ? 'transform 0.4s ease-out' : 'transform 0.2s ease'};
  transform: ${(props) =>
    props.isMenuOpen ? 'translate(155px, 12px)' : 'translate(28.5px, 0px)'};
  @media screen and (min-width: 768px) {
    transform: ${(props) =>
      props.isMenuOpen ? 'translate(155px, 12px)' : 'translate(50px, 12px)'};
  }
  &:hover {
    .hamburger-react div {
      background-color: var(--light-grey);
    }
  }
  .hamburger-react {
    cursor: pointer;
    div {
      box-shadow: 1px 1px 3px 1px rgb(0 0 0 / 40%);
      body[style*='overflow: hidden'] & {
        box-shadow: none;
      }
    }
  }
`;
