import React from 'react';
import styled from 'styled-components';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';
import { useScroll } from '../../hooks/useScroll';
import { useMenuContext } from '../../state/Menu';

const Nav = () => {
  const { isScrolled } = useScroll();
  const { isMenuOpen, toggleMenu } = useMenuContext();
  return (
    <StyledNav isMenuOpen={isMenuOpen} isScrolled={isScrolled}>
      <DesktopNav />
      <MobileNav />
    </StyledNav>
  );
};

export default Nav;

const StyledNav = styled.div`
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  flex-flow: column nowrap;
  height: ${(props) => (props.isScrolled ? '50px' : '60px')};
  @media screen and (min-width: 768px) {
    height: ${(props) =>
      props.isScrolled || props.isMenuOpen ? '70px' : '125px'};
  }
  background: ${(props) =>
    props.isScrolled && !props.isMenuOpen
      ? 'linear-gradient(rgba(0,0,0, 1), rgba(0,0,0, 0.75))'
      : 'rgba(0,0,0,0)'};
  transition: height, background 0.4s ease-out;
`;
