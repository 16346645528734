import React, { useState, useRef, useEffect } from 'react';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import Footer from './Footer';
import Nav from './Nav';
import { MenuProvider } from '../state/Menu';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import LoadingAnimation from './LoadingAnimation';
const ContentWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
  .site-content {
    flex-grow: 1;
    height: 100%;
  }
`;

export default function Layout({ children, location }) {
  const [showAnimation, setShowAnimation] = useState(false);
  const animationRef = useRef({
    location: null,
  });
  useEffect(() => {
    // set the location on initial load
    if (!animationRef.current.location) {
      if (!showAnimation) {
        setShowAnimation(true);
      }
      animationRef.current.location = location.pathname;
      // then make sure dialog is closed on route change
    } else if (animationRef.current.location !== location.pathname) {
      if (!showAnimation) {
        setShowAnimation(true);
      }
      animationRef.current.location = location.pathname;
    }
  }, [showAnimation, location, setShowAnimation]);
  const MotionLoadingAnimation = motion(LoadingAnimation);
  function hideAnimation() {
    setShowAnimation(false);
  }
  return (
    <>
      <GlobalStyles />
      <Typography />
      <AnimatePresence exitBeforeEnter initial={false}>
        <ContentWrapper>
          {showAnimation && (
            <MotionLoadingAnimation
              parentState={hideAnimation}
              ref={animationRef}
              key="test"
            />
          )}
          <MenuProvider>
            <Nav />
          </MenuProvider>
          <motion.main
            initial={{
              y: '100vh',
              transition: {
                y: { ease: [0.19, 1, 0.22, 1], duration: 1.5 },
              },
            }}
            animate={{
              y: '0',
              transition: {
                y: { ease: [0.19, 1, 0.22, 1], duration: 1.5 },
              },
            }}
            exit={{
              y: '-100vh',
              transition: {
                y: { ease: [0.19, 1, 0.22, 1], duration: 1.5 },
              },
            }}
            className="site-content"
            key="main"
          >
            {children}
          </motion.main>
          <Footer />
        </ContentWrapper>
      </AnimatePresence>
    </>
  );
}
