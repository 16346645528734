import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const AnimationContainer = styled(motion.div)`
  background-color: var(--black);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6;
`;

const StyledIcon = styled(motion.svg)`
  width: 50vw;
  height: auto;
  .path {
    fill: none;
    stroke: var(--white);
    stroke-width: 5px;
  }
  @media screen and (min-width: 768px) {
    width: 25vw;
  }
`;

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const delay = i;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: 'spring', duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.01 },
      },
    };
  },
};
export default function LoadingAnimation({ parentState }) {
  return (
    <AnimationContainer
      variants={{
        initial: {
          y: '100vh',
          transition: {
            y: { ease: [0.19, 1, 0.22, 1], duration: 1 },
          },
        },
        visible: {
          y: 0,
          transition: {
            y: { ease: [0.19, 1, 0.22, 1], duration: 1 },
          },
        },
        exit: {
          y: '-100vh',
          transition: {
            y: { ease: [0.19, 1, 0.22, 1], duration: 0.5 },
          },
        },
      }}
      initial="initial"
      animate="visible"
      exit="exit"
    >
      <StyledIcon
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1161.35 645.33"
        initial="hidden"
        animate="visible"
        key="svg"
      >
        <g>
          <motion.path
            variants={draw}
            custom={1.5}
            className="path"
            d="m9.76 508.12 28.11-31.38-.98 43.54-36.1 39.24 8.97-51.4zM25.71 550.17l21.06-16.94v27.01L26.1 570.57l-.39-20.4zM45.72 504.13l2.16 19.42 19.75-13.53v-25.11l-21.91 19.22zM35.52 447.57l-2.81 23.54-17.27 7.52 1.18-21.58 18.9-9.48z"
          />
          <motion.path
            variants={draw}
            custom={1.4}
            className="path"
            d="m37.87 489.03 57.68-61.99 11.57-85.34-56.5 66.31-17.85 86.31 5.1-5.29z"
            onAnimationComplete={() => {
              parentState();
            }}
          />
          <motion.path
            variants={draw}
            custom={1.3}
            className="path"
            d="m52.34 392.51 67.93-83L105 411.15s85.53-82 144-94.95c0 0 10.55-347.2 198.09-276.2 0 0-57.94-84.74-179.17 40.94-29.35 30.42-73.5 76-111.82 131.17-69.92 100.7-65.51 109.05-103.76 180.4Z"
          />
          <motion.path
            variants={draw}
            custom={1.2}
            className="path"
            d="M260.72 328.17s-55.32 26.09-114 71.89c0 0 10.59-7.55 51.2 25.8s35.51 116.72 40.41 133-4.51 41.59 103 60.43 313 51.33 420.26-10.4l-25.76 9.41s-33.35 9.81-57.68 6.28-56.31-13.06-74.83-38.15c-28.22-38.22-80.17-136.1-76.35-279.91C533.53 60.39 431.85 35 400.4 35c-60.69 0-143.34 89.74-139.68 293.17Z"
          />
          <motion.path
            variants={draw}
            custom={1.1}
            className="path"
            d="M541.64 291.09S868.59 14.36 1004.86.5c0 0-147.39 43.42-159.81 309.69-3.67 78.68 8.69 132.76 3.92 162-5.62 34.53-15.63 77.69-93.11 126.47C721 620.67 686.74 621 662.09 612c-46.42-16.84-71.24-68-77.49-84.55-21.77-57.71-46.36-122.32-42.96-236.36Z"
          />
          <motion.path
            variants={draw}
            custom={1}
            className="path"
            d="m1160.79 321.11-297 90.17s-18-232.9 73.3-336.76c79.54-90.52 154.35-164 223.7 246.59Z"
          />
        </g>
      </StyledIcon>
    </AnimationContainer>
  );
}
