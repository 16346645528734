import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --white: #fff;
    --lightgrey: #DFDFDF;
    --midlightgrey: #B1B1B1;
    --midgrey:  #8D8D8D;
    --darkgrey: #606060;
    --charcoal: #1F1F1F;
    --black: #000000;
    --cast: 2px;
  }

  * {
  box-sizing: border-box;
}

  html,body{
  height: 100%;
  width: 100%;
  overscroll-behavior-y: none;
}


  html {
    background-color: var(--white);
    background-size: 450px;
    background-attachment: fixed;
    font-size: 10px;
  }
  #___gatsby{
    height: 100vh;
  }

  body {
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
  }
  .
  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

   a.btn {
    background-color: var(--black);
    color: white;
    border: 0;
    padding: 1.6rem 3.2rem;
    border-radius: 3px;
    cursor: pointer;
    text-decoration: none;
    will-change: box-shadow;
    box-shadow: 0.1em 0.1em 0.5em rgba(0,0,0, 65%);
    transition: background-color, box-shadow 0.6s cubic-bezier(0.43, 0.13, 0.23, 0.96);
    &:hover {
      box-shadow: none;
      cursor: pointer;
    }
    
  }
  p{
    line-height: 1.5em;
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }


  hr {
    border: 0;
    height: 8px;
    background-size: 1500px;
  }

  img {
    max-width: 100%;
  }

}
 
[data-reach-dialog-overlay]{
  z-index: 5;
}
[data-reach-dialog-content]{
  width: 85vw;
}
input,
textarea,
button,
select,
a, 
div {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}


`;

export default GlobalStyles;
