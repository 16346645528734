import { createGlobalStyle } from 'styled-components';

import displayFontWoff from '../assets/fonts/WOFF/Poppins-Medium.woff';
import displayFontWoff2 from '../assets/fonts/WOFF2/Poppins-Medium.woff2';
import displayFontTtf from '../assets/fonts/TTF/Poppins-Medium.ttf';
import textFontWoff from '../assets/fonts/WOFF/OpenSans-Regular.woff';
import textFontWoff2 from '../assets/fonts/WOFF2/OpenSans-Regular.woff2';
import textFontTtf from '../assets/fonts/TTF/OpenSans-Regular.ttf';

const Typography = createGlobalStyle`

  @font-face {
    font-family: OpenSans;
    src: url(${textFontWoff2}) format('woff2');
    src: url(${textFontWoff}) format('woff');
    src: url(${textFontTtf}) format('truetype');
    font-style: normal;
    font-weight: normal;
    font-display: swap;
  }
  @font-face {
    font-family: Poppins;
    src: url(${displayFontWoff2}) format('woff2');
    src: url(${displayFontWoff}) format('woff');
    src: url(${displayFontTtf}) format('truetype');
    font-style: normal;
    font-weight: normal;
    font-display: swap;
  }

  html {
    font-family: OpenSans, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--black);
  }
  p, li {
    letter-spacing: 0.5px;
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: Poppins;
    font-weight: normal;
    margin: 0;
  }
  h1{
    --minFontSize: 27px;
    --maxFontSize:  38px;
    --scaler: 5vw;
    font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize));
  }
  h2{
    font-weight: regular;
    --minFontSize: 25px;
    --maxFontSize: 30px;
    --scaler: 5vw;
    font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize));
  }
  h3{
    font-weight: regular;
    --minFontSize: 19px;
    --maxFontSize: 25px;
    --scaler: 5vw;
    font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize));
  }
  .center {
    text-align: center;
  }

`;

export default Typography;
