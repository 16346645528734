import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useMenuContext } from '../../state/Menu';
import { StaticImage } from 'gatsby-plugin-image';

const LogoLink = ({ isScrolled, className }) => {
  const { closeMenu } = useMenuContext();
  return (
    <StyledLogo
      className={className}
      scrolled={isScrolled ? 1 : 0}
      title="Home"
      to="/"
      onClick={closeMenu}
    >
      <StaticImage
        className="logo"
        src="../../images/mobile-branding.png"
        alt="SMS Logo"
        placeholder="none"
      />
    </StyledLogo>
  );
};

export default LogoLink;

const StyledLogo = styled(Link)`
  margin: 0;
  & + [aria-expanded='true'] {
    display: none;
  }
  .logo {
    width: 150px;
    filter: ${(props) =>
      props.scrolled ? 'none' : 'drop-shadow(1px 1px 1px black)'};
    @media (min-width: 768px) {
      width: 250px;
      transition: transform 0.4s ease-out;
      transform: ${(props) => (props.scrolled ? 'scale(.88)' : 'scale(1)')};
    }
  }
`;
