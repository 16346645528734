import React from 'react';
import Layout from './src/components/Layout';
import { AnimatePresence } from 'framer-motion';
import 'normalize.css';

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>
    <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
  </Layout>
);
